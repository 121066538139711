<template>
  <div class="col-12" style="padding-left: 0; padding-right: 0">
    <div class="white-box">
      <div class="white-box_header">Услуги</div>

      <div class="white-box_body items">
        <template v-if="!services.data">Услуг еще нет</template>
        <template v-else>
          <div class="row" v-for="(service, key) in services.data" :key="key">
            <div class="col-12 col-md-4">{{ service.name }}</div>
            <div class="col-12 col-md-4">
              <template v-if="service.disabled">Приостановлена</template>
              <template v-else>Активна</template>
            </div>
            <div class="col-12 col-md-4">
              <div style="display: inline-block">{{ service.amount }} р.</div>
            </div>

            <template v-if="service.info">
              <div class="col-12 col-md-12">
                <div
                  style="
                    display: inline-block;
                    font-weight: bold;
                    text-align: justify;
                    padding-top: 10px;
                  "
                >
                  <div v-html="service.info"></div>
                </div>
              </div>
            </template>
          </div>

          <template v-if="services.meta">
            <template v-for="(note, key) in services.meta.notes">
              <div class="row" :key="key">
                <div class="col-12 col-md-12">
                  <div
                    style="
                      display: inline-block;
                      font-weight: bold;
                      text-align: justify;
                      padding-top: 10px;
                    "
                  >
                    <div v-html="note.text"></div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  computed: {
    services() {
      return this.$store.getters["account/getServices"];
    },
    /*servicesFinalAmount() {
          return parseFloat(this.services.map(item => item.amount).reduce((prev, next) => parseFloat(prev) + parseFloat(next))).toFixed(2);
        }*/
  },
};
</script>
