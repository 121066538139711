var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"c-button",class:[
    _vm.fullSize ? 'is-full-size' : '',
    _vm.type ? 'c-button--' + _vm.type : '',
    _vm.buttonSize ? 'c-button--' + _vm.buttonSize : '',
    {
      'is-disabled': _vm.buttonDisabled,
      'is-plain': _vm.plain,
      'is-round': _vm.round,
    }
  ],attrs:{"disabled":_vm.buttonDisabled,"type":_vm.nativeType},on:{"click":_vm.handleClick}},[(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }