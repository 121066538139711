<template>
  <div class="tariff-wrapper">
    <div class="white-box_header">
      Текущий тариф
    </div>
    <!--<h5 class="orange-underline">Текущий тариф</h5>-->
    <div class="white-box_body">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-8 col-xl-8">
          <tariff-card
            :id="currentTariff.id"
            :name="currentTariff.name"
            :speed-night="parseInt(currentTariff.speed.night)"
            :speed-day="parseInt(currentTariff.speed.day)"
            :speed-unit="currentTariff.speed.unit"
            :bonus-text="currentTariff.bonus"
            :price="parseFloat(currentTariff.amount)"
            :credit-disabled="currentTariff.credit.disabled"
            :credit-expired="currentTariff.credit.expired"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import TariffCard from "./card.vue";

export default {
  name: "TariffCurrent",
  props: {
    currentTariff: {
      type: Object,
      default: null
    },
    services: {
      type: Array,
      default: null
    }
  },
  data: function() {
    return {};
  },
  components: {
    // MoonLoader,
    TariffCard
  },
  computed: {
    ...mapState({})
  },
  methods: {},
  mounted() {}
};
</script>
