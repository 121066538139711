<template>
  <div 
    class="sidebar column" 
    role="navigation">
    <ul class="nav side-menu">
      <li class="nav-item">
        <router-link 
          class="router" 
          :to="{ name: 'home' }">
          <i class="fa fa-home fa-fw"/>Мой Курьер плюс
        </router-link>
      </li>
      <li class="nav-item">
        <a 
          href="#payments" 
          data-toggle="collapse" 
          aria-expanded="false">
          <i class="fa fa-credit-card fa-fw"/><div class="navbar-text">Платежи</div>
        </a>
        <ul 
          class="collapse list-unstyled nav-second-level" 
          id="payments">
          <li class="">
            <router-link 
              class="router" 
              to="detalization">
              <i class="fa fa-angle-right fa-fw"/>Детализация
            </router-link>
          </li>
          <li class="">
            <router-link 
              class="router" 
              to="pay">
              <i class="fa fa-angle-right fa-fw"/>Оплата услуг
            </router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a 
          href="#internet" 
          data-toggle="collapse" 
          aria-expanded="false">
          <i class="fa fa-globe fa-fw"/><div class="navbar-text">Интернет</div>
        </a>
        <ul 
          class="collapse list-unstyled nav-second-level" 
          id="internet">
          <li class="">
            <router-link 
              class="router" 
              to="tariffs">
              <i class="fa fa-angle-right fa-fw"/>Тарифы
            </router-link>
          </li>
          <li class="">
            <router-link 
              class="router" 
              to="info">
              <i class="fa fa-angle-right fa-fw"/>Реквизиты
            </router-link>
          </li>
          <li class="">
            <router-link 
              class="router" 
              to="stats">
              <i class="fa fa-angle-right fa-fw"/>Статистика
            </router-link>
          </li>
          <li class="">
            <router-link
                class="router"
                to="/internet-options">
              <i class="fa fa-angle-right fa-fw"/>Настройки
            </router-link>
          </li>
        </ul>
      </li>
      <!--<li class="nav-item">
        <router-link
          class="router"
          :to="{ name: 'services' }">
          <i class="fa fa-list fa-fw"/>Активные услуги
        </router-link>
      </li>-->
      <!--<li class="nav-item d-lg-none">
        <router-link 
          class="router" 
          :to="{ name: 'profile' }">
          <i class="fa fa-user fa-fw"/>Профиль
        </router-link>
      </li>-->
      <li class="nav-item">
        <router-link 
          class="router" 
          :to="{ name: 'messages' }">
          <i class="fa fa-envelope fa-fw"/><div class="navbar-text">Уведомления</div>
          <template v-if="badgeMessages > 0">
            <div class="btn-badge btn-round btn-badge-round" >
              {{ badgeMessages }}
            </div>
          </template>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link 
          class="router" 
          to="support">
          <i class="fa fa-question-circle fa-fw"/><div class="navbar-text">Техподдержка</div>
          <template v-if="badgeChat > 0">
            <p class="btn-badge btn-round btn-badge-round">
              {{ badgeChat }}
            </p>
          </template>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link 
          class="router" 
          to="cams">
          <i class="fa fa-video-camera fa-fw"/><div class="navbar-text">Камеры</div>
        </router-link>
      </li>
      <li class="nav-item d-lg-none">
        <router-link 
          class="router" 
          to="apps">
          <i class="fa fa-mobile fa-fw"/><div class="navbar-text">Приложения</div>
        </router-link>
      </li>
      <li class="nav-item" >
        <a
          class="router"
          href="http://phone.shadrinsk.net">
          <i class="fa fa fa-external-link fa-fw fa-fw"/>ЛК телефонии
        </a>
      </li>
      <!--<li class="nav-item d-lg-none">
        <a 
          href="#" 
          @click="exit()">
          <i class="fa fa-sign-out fa-fw"/>Выход
        </a>
      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },

  components: {
    //FooterComponent
  },

  computed: {
    badgeMessages: {
      get() {
        return this.$store.getters["account/getBadgesMessages"];
      }
    },

    badgeChat: {
      get() {
        return this.$store.getters["account/getBadgesChat"];
      }
    }
  },

  mounted: function() {
    $(document).ready(function() {
      $("a.router").click(function() {
        if ($(window).width() <= 768) {
          $("body").toggleClass("enlarged");
        }
      });
    });
  },

  methods: {
    exit: function() {
      let currentToken = null;
      if (window.localStorage) {
        currentToken = window.localStorage.getItem(
          "sentFirebaseMessagingToken"
        );
      }
      if (currentToken) {
        this.$store.dispatch(
          "deleteDeviceId",
          window.localStorage.getItem("sentFirebaseMessagingToken")
        );
        window.localStorage.setItem("sentFirebaseMessagingToken", "");
      }
      Promise.all([
        this.$store.dispatch("exit"),
        this.$store.dispatch("clearAll")
      ]).then(() => {
        this.$router.push({ path: "/" });
      });
    }
  }
};
</script>