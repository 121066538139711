<template>
  <div class="chat-items-group">
    <div class="messenger--datetime">
      <small>{{ timestamp }}</small>
    </div>
    <chat-item
      v-for="(m, key) in messages"
      :key="key"
      :id="'message' + key"
      :message="m"
    />
  </div>
</template>

<script>
import ChatItem from "./item.vue";
export default {
  name: "ChatGroup",
  components: {
    ChatItem
  },
  props: {
    timestamp: {
      type: String,
      default: null
    },
    messages: {
      type: Object,
      default: null
    }
  }
};
</script>

<style>
</style>
