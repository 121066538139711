<template>
  <div class="cams-wrapper">
    <template v-if="showModal">
      <cams-modal-window-component
        :show="showModal"
        :selected-camera="selectedCamera"
        :hls-url="hlsUrl"
        @close="closeModalForm"/>
    </template>
    <template v-if="isLoading">
      <loader-component/>
    </template>
    <!--<div v-else-if="isSuccess">
      <div class="row">

        <template v-if="cams.length > 0">
          <div
            v-for="item in cams"
            class="col-md-12">
            <div class="camera">
              <div class="name">
                {{ item.Name }}
              </div>
              <div class="cam-preview">
                <img
                  :src="item.PreviewUrl"
                  @click="showModalForm(item)">
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-12">
            Вам не доступно ни одной камеры.
          </div>
        </template>
      </div>-->

    <!--<template v-if="isLoading">
        <loader-component/>
      </template>-->

    <div v-else-if="isSuccess">
      <iframe
        style="display: block; width: 100%; height: calc(100vh - 60px); border: none;" 
        :src="embedClientUrl" 
        allowfullscreen/>
    </div>
    <div
      class="container"
      v-else-if="!isSuccess">
      <error-component
        :message="message"
        @refresh="fetchCams(true)"/>
    </div>
  </div>
</template>

<script>
import store from "../../vuex/fpst_store";
import LoaderComponent from "./LoaderComponent.vue";
import ErrorComponent from "./ErrorComponent.vue";

export default {
  name: "CamsComponent",

  components: {
    ErrorComponent,
    LoaderComponent,
  },

  data: function() {
    return {
      isLoading: false,
      isSuccess: true,
      message: null,
      showModal: false,
      selectedCamera: null
    };
  },

  computed: {
    fpstSession: state => state.fpstSession,

    embedClientUrl() {
      return this.$store.getters["getEmbedClientUrl"];
    },

    cams() {
      return store.getters["cams/getCams"];
    },

    categories() {
      return store.getters["cams/getCategories"];
    }
  },

  methods: {
    setCams() {
      /*this.isLoading = true;
      this.$store.dispatch('fetchFpstSession')
        .then(() => {
          store.dispatch('cams/fetchCams')
            .then(() => {
              store.dispatch('cams/fetchPreview').then(() => {
                this.isLoading = false;
                this.isSuccess = true;
              })
            })
        })
        .catch(error => {
          console.log(error);
          this.message = this.$constants.errors.unexpected;
          this.isSuccess = false;
          this.isLoading = false
        })
    },*/

      this.isLoading = true;
      this.$store
        .dispatch("fetchFpstEmbedClientUrl")
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.isSuccess = false;
            if (error.response.status == 500) {
              let errorCode = error.response.data.code;
              if (errorCode) {
                switch (errorCode) {
                  case 52:
                    //не оплачен
                    this.message = error.response.data.message;
                    break;
                  case 53:
                    //занято
                    this.message =
                      "Мы подготавливаем ваши камеры. Это может занять несколько минут.";
                    //error.response.data.message
                    break;
                  case 54:
                    //не удалось захватить замок
                    this.message =
                      "Мы подготавливаем ваши камеры. Это может занять несколько минут.";
                    //error.response.data.message
                    break;
                  default:
                    this.message = this.$constants.errors.unexpected;
                  //error.response.data.message
                }
              } else {
                this.message = this.$constants.errors.unexpected;
              }
            } else if (error.response.status == 403) {
              let errorCode = error.response.data.code;
              if (errorCode) {
                switch (errorCode) {
                  case 52:
                    //не оплачен
                    this.message = error.response.data.message;
                    break;
                  default:
                    this.message = this.$constants.errors.unexpected;
                    break;
                }
              } else {
                this.message = this.$constants.errors.unexpected;
              }
            } else {
              this.message = this.$constants.errors.unexpected;
            }
          } else {
            this.isLoading = false;
            this.isSuccess = false;
            this.message = this.$constants.errors.unexpected;
          }
        });
    },

    fetchCams(forced = false) {
      if (forced) {
        this.setCams();
      } else {
        if (!this.embedClientUrl) {
          this.setCams();
        }
      }
    },

    showModalForm(camera) {
      this.hlsUrl = camera.HLS;
      this.selectedCamera = camera;
      this.showModal = true;
    },

    closeModalForm() {
      this.showModal = false;
      store
        .dispatch("cams/stopTranslation", this.selectedCamera.HlsUrl)
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  beforeCreate() {},

  created: function() {},

  mounted() {
    this.fetchCams(true);
  }
};
</script>

<style>

