<template>
    <div>
        <transition name="modal">
            <div
                    class="modal-mask"
                    @click="close"
                    v-show="show" >
                <div class="modal-dialog modal-dialog-scrollable">
                    <div
                            class="modal-content"
                            style="min-height: 95px"
                            @click.stop>

                        <template v-if="closable || thisHeader">
                            <div class="modal-header">
                                <h3 v-if="thisHeader">{{ thisHeader }}</h3>
                                <button
                                        v-if="closable"
                                        type="button"
                                        class="close"
                                        @click="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </template>

                        <div class="modal-body">
                            <div
                                    v-show="showMobileAppsPromo"
                                    class="auth-apps-links alert alert-warning"
                                    style="">
                                <div class="row">
                                    <div class="col-12">
                                        <div>
                                            {{ this.$constants.mobileAppPromo.promised }}
                                        </div>
                                        <a
                                                href="https://play.google.com/store/apps/details?id=net.shadrinsk.courierplus&amp;hl=ru"
                                                target="_blank">
                                            <img
                                                :src="require('@/assets/images/googleplay.jpg')"
                                                width="150"
                                                height="50"
                                                alt="android"
                                            >
                                        </a>
                                        <a
                                                href="https://itunes.apple.com/ru/app/%D0%BA%D1%83%D1%80%D1%8C%D0%B5%D1%80-%D0%BF%D0%BB%D1%8E%D1%81/id1312778211?mt=8"
                                                target="_blank">
                                            <img
                                                :src="require('@/assets/images/appstore.jpg')"
                                                width="150"
                                                height="50"
                                                alt="ios">
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <template v-if="thisMessage">
                                <template v-if="Array.isArray(thisMessage)">
                                    <p v-for="(m, key) in thisMessage" :key="key">
                                        {{ m }}
                                    </p>
                                </template>
                                <template v-else>
                                    {{ thisMessage }}
                                </template>
                            </template>
                            <template v-else>
                                <loader-component/>
                            </template>
                        </div>

                        <div
                                class="modal-footer text-right"
                                v-if="buttons">
                            <template
                                    v-for="(button, key) in thisButtons"
                            >
                                <button-element
                                        :key="key"
                                        round
                                        type="medium"
                                        @click="$emit(button.emitEvent)"
                                >
                                    {{ button.text }}
                                </button-element>
                            </template>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import ButtonElement from '../button/button.vue';
    import LoaderComponent from "../common/LoaderComponent.vue";

    export default {

        name: 'ModalWindow',

        components: {
            ButtonElement,
            LoaderComponent
        },

        props: {
            showMobileAppsPromo: {
                type: Boolean,
                default: false
            },
            show: {
                type: Boolean,
                default: false
            },
            closable: {
                type: Boolean,
                default: false,
            },
            headerText: {
                type: String,
                default: null
            },
            text: {
                type: String,
                default: null
            },
            buttons: {
                type: Array,
                default: null
            }
        },

        data: function() {
            return {
                thisMessage: this.text,
                thisHeader: this.headerText,
                thisButtons: this.buttons
            };
        },

        watch: {
            text() {
                this.thisMessage = this.text;
            },
            headerText() {
                this.thisHeader = this.headerText;
            },
            buttons() {
                this.thisButtons = this.buttons;
            }
        },

        methods: {
            close: function() {
                if (this.closable) {
                    this.$emit("close");
                }
            },
            keydown(e) {
                if (e.keyCode === 27) {
                    this.close();
                }
            }
        },

        mounted() {
            document.addEventListener("keydown", this.keydown);
        },
        beforeDestroy() {
            document.removeEventListener("keydown", this.keydown);
        }

    }
</script>

<style lang="scss">
    @import "./modal.sass";
</style>