<template>
  <button
    class="c-button"
    @click="handleClick"
    :disabled="buttonDisabled"
    :type="nativeType"
    :class="[
      fullSize ? 'is-full-size' : '',
      type ? 'c-button--' + type : '',
      buttonSize ? 'c-button--' + buttonSize : '',
      {
        'is-disabled': buttonDisabled,
        'is-plain': plain,
        'is-round': round,
      }
    ]"
  >
    <span v-if="$slots.default">
      <slot/>
    </span>
  </button>
</template>
<script>
export default {
  name: "ButtonElement",
  props: {
    type: {
      type: String,
      default: "default"
    },
    size: {
      type: String,
      default: null
    },
    nativeType: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    fullSize: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    buttonSize() {
      return this.size;
    },
    buttonDisabled() {
      return this.disabled;
    }
  },

  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    }
  }
};
</script>

<style lang="css">
  @import './button.css';
</style>