<template>
  <div class="wrapper">
    <!--<head-component></head-component>-->
    <template v-if="isLoading">
      <loader-component/>
    </template>
    <div v-else-if="isSuccess">
      <network-component :network="network"/>
    </div>
    <error-component 
      v-else-if="!isSuccess"
      :message="message"
      @refresh="updateAccountNetwork(true)"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoaderComponent from "../common/LoaderComponent.vue";
import NetworkComponent from "../common/NetworkComponent.vue";
//import CurrentTariffComponent from "../components/tariffs/CurrentTariffComponent.vue";
import ErrorComponent from "../common/ErrorComponent.vue";
// import HeadComponent from "../common/HeadComponent.vue";

export default {
  name: "PageInfo",
  data: function() {
    return {
      isLoading: false,
      isSuccess: true,
      message: null
    };
  },
  components: {
    LoaderComponent,
    ErrorComponent,
    //CurrentTariffComponent,
    NetworkComponent,
    // HeadComponent
  },
  computed: {
    ...mapState({
      network: state => state.account.network
    })
  },
  methods: {
    updateAccountNetwork(forced = false) {
      if (forced) {
        this.fetchAccountNetwork();
      } else {
        this.fetchAccountNetwork();
      }
    },
    fetchAccountNetwork() {
      if (Object.keys(this.network).length === 0) {
        this.isSuccess = false;
        this.isLoading = true;
        this.$store
          .dispatch("account/fetchNetwork")
          .then(() => {
            this.isSuccess = true;
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            this.isSuccess = false;
            if (error.response) {
              this.message = this.$constants.errors.unexpected;
            } else {
              this.message = this.$constants.errors.unexpected;
            }
          });
      }
    }
  },
  mounted() {
    this.updateAccountNetwork();
  }
};
</script>
