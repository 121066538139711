const cookie = {
  name: "tokens",
  domain: "cabinet.shadrinsk.net",
  expires: "99999h"
};

const api = {
  baseURL: "https://api.shadrinsk.net/",
  client_id: "10",
  client_secret: "BqDvSjHI6CqVjsWmSFL8crXclTBMcbUuqvjm9aMB"
};

const fpst_api = {
  baseURL: "https://cctv.shadrinsk.net/"
};

const payments = {
  terminalKey: "1502818552312"
};

export { cookie, api, fpst_api, payments };