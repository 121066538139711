<template>
  <div class="wrapper">
    <template v-if="isLoading">
      <loader-component/>
    </template>
    <template v-else>
      <template v-if="isSuccess">
        <div class="white-box">
          <div class="white-box_header">
            Трафик в этом месяце
          </div>
          <div class="white-box_body">
            <div class="items">
              <div class="row">
                <div class="col-12 col-md-6">
                  Входящий
                </div>
                <div class="col-12 col-md-6">
                  ≈ {{ lastStatsDownload }} Мбайт
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  Исходящий
                </div>
                <div class="col-12 col-md-6">
                  ≈ {{ lastStatsUpload }} Мбайт
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="white-box">
          <div class="white-box_header">
            За последний год
          </div>
          <div class="white-box_body">
            <stats-component
              :chart-data="chartData"
              :options="{responsive: true, maintainAspectRatio: false}"
              :width="400"
              :height="400"/>
          </div>
        </div>
      </template>
      <template v-else>
        <error-component
          @refresh="updateAccountStats(true)"
          :message="message"/>
      </template>
    </template>
  </div>
</template>

<script>
import StatsComponent from "../common/StatsComponent.vue";
import { mapState } from "vuex";
import LoaderComponent from "../common/LoaderComponent.vue";
import ErrorComponent from "../common/ErrorComponent.vue";

export default {
  name: "Stats",

  components: {
    StatsComponent,
    LoaderComponent,
    ErrorComponent
  },

  data: function() {
    return {
      months: [],
      chartData: null,
      showTable: true,

      isLoading: false,
      isSuccess: true,
      message: null,
    };
  },

  computed: {
    statUpload() {
      return this.$store.getters["account/getStatUpload"];
    },

    statDownload() {
      return this.$store.getters["account/getStatDownload"];
    },

    lastStatsUpload() {
      return this.$store.getters["account/getLastStatsUpload"];
    },

    lastStatsDownload() {
      return this.$store.getters["account/getLastStatsDownload"];
    },

    ...mapState({
      accountInfo: state => state.account.accountInfo,
      tariffs: state => state.account.tariffs,
      banners: state => state.banners.bannersArray
    })
  },

  mounted() {
    this.months = [];
    this.getMonthsList();
    this.updateAccountStats();
  },

  methods: {

    updateAccountStats(forced = false) {
      if (forced) {
        this.fetchAccountStats();
      } else {
        if (
            this.statUpload === null || Object.keys(this.statUpload).length === 0 ||
            this.statDownload === null || Object.keys(this.statDownload).length === 0 ||
            this.lastStatsDownload === null || Object.keys(this.lastStatsDownload).length === 0 ||
            this.lastStatsUpload === null || Object.keys(this.lastStatsUpload).length === 0
            ) {
          this.fetchAccountStats();
        } else {
          this.fillChartData();
        }
      }
    },

    fetchAccountStats() {
      this.isLoading = true;
      this.isSuccess = false;
      this.message = null;

      this.$store.dispatch("account/fetchAccountStats").then(() => {
        this.isLoading = false;
        this.isSuccess = true;
        this.fillChartData();
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
        console.log(this.$constants.errors.unexpected);
        if (error.response) {
          this.message = this.$constants.errors.unexpected;
        } else {
          this.message = this.$constants.errors.unexpected;
        }
      })
    },

    getMonthsList() {
      let currentMonth = this.$moment().month();
      for (let i = 0; i <= currentMonth; i++) {
        this.months.push(this.$moment().month(i).format("MMMM"));
      }
    },

    fillChartData: function() {
      this.chartData = {
        labels: this.months,
        datasets: [
          {
            label: "Входящий, Мбайт",
            backgroundColor: "#ffc880",
            data: Object.values(this.statDownload)
          },
          {
            label: "Исходящий, Мбайт",
            backgroundColor: "#2f458c",
            data: Object.values(this.statUpload)
          }
        ]
      };
    }
  }
};
</script>
