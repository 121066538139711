import * as api from "../../api/courier_api";

const messages = {
  namespaced: "true",
  state: {
    messages: {
      all: [],
      allKeys: [],
      allTotalPages: null,
      allCurrentPage: 0
    },
  },
  mutations: {
    SET_MESSAGES(state, payload) {
      var messages = payload.data.data;
      state.messages.allCurrentPage = payload.data.meta.pagination.current_page;
      state.messages.allTotalPages = payload.data.meta.pagination.total_pages;

      messages.forEach(function(message) {
        let messageId = message.id;
        state.messages.all[messageId] = message;
        if (!state.messages.allKeys.includes(messageId)) {
          state.messages.allKeys.push(messageId);
        }
      });

      state.messages.all = Object.assign(
          {},
          state.messages.all
      );
    },
    UPDATE_MESSAGE_READED_STATUS(state, id) {
      let result = state.messages.all[id];
      if (result !== null) {
        result.readed = true;
      }
    },
    CLEAR_STATE(state) {
      state.messages = {
        all: [],
        allKeys: [],
        allTotalPages: null,
        allCurrentPage: 0
      };
    }
  },
  actions: {
    fetchMessages: ({ commit }, page = 0) => {
      return api.account.getMessages(page).then(data => {
        commit("SET_MESSAGES", { data });
        return data;
      });
    },

    // eslint-disable-next-line no-unused-vars
    readMessage: ({ commit }, id) => {
      return api.account.getMessages(null, id).then(data => {
        // commit('SET_MESSAGES', {data});
        // return data
      });
    },
  },
  getters: {
    allKeys: state => {
      return state.messages.allKeys;
    },
    allMessagesTotalPages: state => {
      return state.messages.allTotalPages;
    },
    allMessagesCurrentPage: state => {
      return state.messages.allCurrentPage
    },
    messages: state => {
      return state.messages.all;
    },
  }
};

export default messages;