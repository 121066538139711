import axios from "axios";
//import account from './vuex/account_store.js'
import store from "../vuex/store";
//import store from './vuex/store'
import * as config from "../config";

const instance = axios.create({
  baseURL: config.fpst_api.baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json"
  }
});

const cams = {
  get() {
    return instance.post("api/GetCameras").then(function(response) {
      return response.data;
    });
  },

  getPreviewImage(cameraId) {
    var data = new URLSearchParams();
    data.append("CameraID", cameraId);
    data.append("Format", "JPG");
    return instance
      .post("api/GetTranslationURL", data)
      .then(function(response) {
        return response.data;
      });
  },

  getHLS(cameraId) {
    var data = new URLSearchParams();
    data.append("CameraID", cameraId);
    data.append("Format", "HLS");
    return instance
      .post("api/GetTranslationURL", data)
      .then(function(response) {
        return response.data;
      });
  },

  stopTranslation(translationUrl) {
    var data = new URLSearchParams();
    data.append("op", "stop");
    return instance.post(translationUrl, data).then(function(response) {
      return response.data;
    });
  },

  getCameraCategories() {
    return instance.post("api/GetCameraCategories").then(function(response) {
      return response.data;
    });
  }
};

instance.interceptors.request.use(function(config) {
  var sessionID = store.getters["getFpstSessionId"];

  console.log(sessionID);

  if (sessionID) {
    if (config.data) {
      config.data.append("SessionID", sessionID);
    } else {
      var data = new URLSearchParams();
      data.append("SessionID", sessionID);
      config.data = data;
    }
  }
  return config;
});

let isRefreshing = false;
const refreshSubscribers = [];

instance.interceptors.response.use(response => {
  const {
    config,
    data: { status }
  } = response;
  const originalRequest = config;

  console.log(originalRequest);

  if (response.data.ErrorCode == 2) {
    if (!isRefreshing) {
      isRefreshing = true;
      store
        .dispatch("fetchFpstSession")
        .then(data => {
          isRefreshing = false;
          refreshSubscribers.map(cb => cb(data.data.session));
        })
        .catch(() => {
          isRefreshing = false;
        });
    }

    const retryOrigReq = new Promise(resolve => {
      refreshSubscribers.push(token => {
        if (originalRequest.data) {
          var result = {};
          originalRequest.data.split("&").forEach(part => {
            var item = part.split("=");
            result[item[0]] = item[1];
          });

          if (result.SessionID) {
            result.SessionID = token;
          }

          var data = new URLSearchParams();

          Object.keys(result).forEach(function(key) {
            data.append(key, result[key]);
          });

          originalRequest.data = data;
        } else {
          var data = new URLSearchParams();
          data.append("SessionID", token);
          originalRequest.data = data;
        }

        resolve(axios(originalRequest));
      });
    });

    return retryOrigReq;
  } else {
    return response;
  }
});

export { instance, cams };
