import * as api from "../../api/courier_api.js";

const banners = {
  namespaced: true,

  state: {
    bannersArray: []
  },

  mutations: {
    SET_BANNERS(state, payload) {
      if (state.bannersArray || state.bannersArray.length > 0) {
        state.bannersArray = [];
      }
      payload.data.forEach(banner => {
        state.bannersArray.push({
          img: banner.img,
          link: banner.to
        });
      });
    },

    CLEAR_STATE(state) {
      state.bannersArray = [];
    }
  },

  actions: {
    fetchBanners: ({ commit }) => {
      console.log("fetchBanners");
      return api.banners.getBanners().then(data => {
        commit("SET_BANNERS", { data });
        return data;
      });
    }
  }
};

export default banners;
