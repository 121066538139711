<template>
  <div class="wrapper">
    <template v-if="isLoading">
      <loader-component/>
    </template>

    <template v-else-if="isSuccess">
      <div class="white-box">
        <div class="row">
          <template v-if="plannedTariff !== null">
            <div
              class="col-md-12 col-lg-6"
              style="padding-right: 0; border-right: 1px solid #eaeefb;">
              <current-tariff
                :current-tariff="currentTariff"
                :services="services"/>
            </div>
            <div
              class="col-md-12 col-lg-6"
              style="padding-right: 0; padding-left: 0;">
              <planned-tariff
                @update="setTariffs(true)"/>
            </div>
          </template>
          <template v-else>
            <div
              class="col-12">
              <current-tariff
                :current-tariff="currentTariff"
                :services="services"/>
            </div>
          </template>
        </div>
      </div>
      <available-tariff
        @update="setTariffs(true)"/>
    </template>
    <error-component
      v-else-if="!isSuccess"
      :message="message"
      @update="setTariffs(true)"/>
  </div>
</template>

<script>
import AvailableTariff from "../tariff/available";
import CurrentTariff from "../tariff/current";
import PlannedTariff from "../tariff/planned";

import LoaderComponent from "../common/LoaderComponent.vue";
import ErrorComponent from "../common/ErrorComponent.vue";

export default {
  name: "PageTariffs",

  components: {
    ErrorComponent,
    AvailableTariff,
    CurrentTariff,
    PlannedTariff,
    LoaderComponent
  },

  computed: {
    services() {
      return this.$store.getters["account/getServices"];
    },

    plannedTariff() {
      return this.$store.getters["tariffs/getPlannedTariff"];
    },

    currentTariff() {
      return this.$store.getters["tariffs/getCurrentTariff"];
    },

  },

  data: function() {
    return {
      isLoading: false,
      isSuccess: true
    };
  },

  methods: {
    setTariffs(forced = false) {
      if (forced) {
        this.fetchTariffs();
      } else {
        if (
          (!this.currentTariff &&
            Object.keys(this.currentTariff).length === 0)
        ) {
          this.fetchTariffs();
        }
      }
    },

    fetchTariffs() {
      this.isLoading = true;
      this.$store
        .dispatch("tariffs/fetchTariffs")
        .then(() => {
          console.log('fetchTariffs success');
          this.isLoading = false;
          this.isSuccess = true;
        })
        .catch(error => {
          console.log('fetchTariffs error: ' + error);
          this.isLoading = false;
          this.isSuccess = false;
          if (error.response) {
            this.message = this.$constants.errors.unexpected;
          } else {
            this.message = this.$constants.errors.unexpected;
          }
        });
    }
  },

  beforeMount() {
    this.setTariffs();
  }
};
</script>