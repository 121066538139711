<template>
  <div class="wrapper">
    <div class="white-box">
      <div class="white-box_header">Настройки интернета</div>
      <div class="white-box_body">
        <template v-if="isLoading">
          <loader-component />
        </template>
        <template v-else-if="isSuccess">
          <internet-settings-component @change="change" :settings="settings" />
        </template>
        <template v-else-if="!isSuccess">
          <error-component
            v-on:refresh="fetchInternetSettings"
            :message="errorMessage"
          >
          </error-component>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComponent from "../common/LoaderComponent";
import { mapState } from "vuex";
import Message from "../message/main";
import InternetSettingsComponent from "../common/InternetSettingsComponent";
import ErrorComponent from "../common/ErrorComponent";

export default {
  name: "InternetSettings",
  data: function () {
    return {
      isLoading: true,
      isSuccess: true,
      errorMessage: null,
    };
  },
  components: {
    InternetSettingsComponent,
    LoaderComponent,
    ErrorComponent,
  },
  computed: {
    ...mapState({
      settings: (state) =>
        state.account.internetSettings.data.switches.sort(
          (a, b) => a.order - b.order
        ),
    }),
  },
  methods: {
    fetchInternetSettings() {
      this.isLoading = true;
      this.$store
        .dispatch("account/fetchInternetSettings")
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;
        })
        .catch(() => {
          this.isLoading = false;
          this.isSuccess = false;
          this.errorMessage = this.$constants.errors.unexpected;
        });
    },

    change(url) {
      this.isLoading = true;

      this.$store
        .dispatch("account/changeInternetSettings", url)
        .then((data) => {
          this.$store
            .dispatch("account/fetchInternetSettings")
            .then(() => {
              this.isLoading = false;
              this.isSuccess = true;

              window.console.log(data.data.url);
              if (undefined !== data.data.url && null !== data.data.url) {
                window.open(data.data.url, "_blank");
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.isSuccess = false;
              this.errorMessage = this.$constants.errors.unexpected;
            });
        })
        .catch((error) => {
          this.$store
            .dispatch("account/fetchInternetSettings")
            .then(() => {
              this.isLoading = false;
              this.isSuccess = true;

              Message({
                message: error.response.data.message,
                position: "center",
                showClose: true,
                type: "error",
              });
            })
            .catch(() => {
              this.isLoading = false;
              this.isSuccess = false;
              this.errorMessage = this.$constants.errors.unexpected;
            });
        });
    },
  },
  mounted() {
    this.fetchInternetSettings();
  },
};
</script>
