<template>
  <div
      class="container"
      style="height: 100%;">
    <div
        class="login-box d-flex align-items-center justify-content-center"
        style="flex-direction: column; height: 100%;">
      <div class="card">
        <div class="card-header">
          <img :src="require('@/assets/images/logo_kuryer_white.png')">
        </div>
        <div class="card-body">
          <!--<p v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors">{{ error }}</li>
            </ul>
          </p>-->
          <label
              for="username"
              class="control-label">{{ this.$constants.login.prompt_account }}</label>
          <input
              @keyup.enter="submit()"
              type="text"
              required
              class="form-control ym-disable-keys"
              id="username"
              placeholder="Логин"
              v-model="credentials.username"
          >
          <label
              for="username"
              class="control-label">{{ this.$constants.login.prompt_password }}</label>
          <input
              @keyup.enter="submit()"
              type="password"
              required
              class="form-control ym-disable-keys"
              id="password"
              placeholder="Пароль"
              v-model="credentials.password"
          >
        </div>
        <div class="card-footer">
          <button-element
              round
              @click="submit"
          >
            Войти
          </button-element>
          <a
              class="link"
              href="#"
              @click="loginHelp">Не могу войти</a>
        </div>


      </div>

      <div
          class="auth-apps-links"
          style="text-align: center; padding-bottom: 16px">

        <div>
          Приложения для мобильных устройств
        </div>

        <a
            href="https://play.google.com/store/apps/details?id=net.shadrinsk.courierplus&amp;hl=ru"
            target="_blank">
          <img
              :src="require('@/assets/images/googleplay.jpg')"
              alt="">
        </a>
        <a
            href="https://itunes.apple.com/ru/app/%D0%BA%D1%83%D1%80%D1%8C%D0%B5%D1%80-%D0%BF%D0%BB%D1%8E%D1%81/id1312778211?mt=8"
            target="_blank">
          <img
              :src="require('@/assets/images/appstore.jpg')"
              alt="">
        </a>
      </div>

      <div>
        <a href="http://phone.shadrinsk.net">
          Личный кабинет телефонии
        </a>
      </div>


    </div>

    <modal-window
        v-if="showModal"
        :show="showModal"
        :closable="closable"
        :header-text="header"
        :text="message"
        @close="closeModalForm"
    />
  </div>
</template>

<script>
    import router from "../../router";
    import ButtonElement from "../button/button";
    import ModalWindow from "../modal/main";

    export default {
        name: "AuthComponent",

        data() {
            return {
                credentials: {
                    username: "",
                    password: ""
                },

                //errors: [],

                header: null,
                message: null,
                showModal: false,
                closable: false,
            };
        },

        components: {
            ButtonElement,
            ModalWindow
        },
        methods: {
            /*checkForm: function (e) {
              if (this.credentials.username && this.credentials.password) {
                return true;
              }
              this.errors = [];
              this.errors.push('Необходимо заполнить все поля');
              e.preventDefault();
              return false;
            },*/

            loginHelp() {
                this.header = this.$constants.login.help_header;
                this.message = this.$constants.login.help_text;
                this.closable = true;
                this.showModal = true;
            },

            submit() {
                //this.checkForm() &&
                if (!this.showModal) {
                    var credentials = {
                        username: this.credentials.username,
                        password: this.credentials.password
                    };
                    if (
                        !credentials.username ||
                        credentials.username.length === 0 ||
                        (!credentials.password || credentials.password.length === 0)
                    ) {
                        this.header = "Ошибка";
                        this.message = this.$constants.login.error_login_and_password_required;
                        this.showModal = true;
                        this.closable = true;
                    } else {
                        this.message = "";
                        this.showModal = true;
                        console.log("login");
                        this.$store
                            .dispatch("login", [credentials.username, credentials.password])
                            .then(() => {
                                this.showModal = false;
                                router.push({path: "/home"});
                            })
                            .catch(error => {
                                console.log(error);
                                this.header = "Ошибка";
                                this.closable = true;
                                if (error.response) {
                                    if (error.response.status == 400) {
                                        this.message = this.$constants.login.error_wrong_login_or_password;
                                        this.showModal = true;
                                    } else if (error.response.status == 500) {
                                        this.message = this.$constants.errors.server_unavailable;
                                        this.showModal = true;
                                    } else {
                                        this.message = this.$constants.errors.unexpected;
                                        this.showModal = true;
                                    }
                                } else {
                                    this.message = this.$constants.errors.unexpected;
                                    this.showModal = true;
                                }
                            });
                    }
                }
            },

            closeModalForm() {
                this.showModal = false;
                this.message = null;
                this.header = null;
                this.closable = false;
            }
        }
    };
</script>
