<template>
  <transition name="c-message-fade">
    <div
      :class="[
        'c-message',
        showClose ? 'c-message_closable' : '',
        positionClass,
        typeClass,
        customClass
      ]"
      v-show="visible"
      @mouseenter="clearTimer"
      @mouseleave="startTimer"
      role="alert">
      <slot>
        <p 
          v-html="message" 
          class="c-message__content"/>
      </slot>
      <i 
        v-if="showClose" 
        class="c-message__close fa fa-times" 
        @click="close"/>
    </div>
  </transition>
</template>

<script type="text/babel">
const typeMap = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error"
};

const positionMap = {
  center: "center",
  right: "right",
  left: "left"
};

export default {
  data() {
    return {
      visible: false,
      message: "",
      duration: 5000,
      type: "info",
      customClass: "",
      onClose: null,
      showClose: false,
      closed: false,
      timer: null,
      center: false,
      position: "center"
    };
  },

  computed: {
    typeClass() {
      return this.type
        ? `c-message--${typeMap[this.type]}`
        : "";
    },
    positionClass() {
      return `c-message_position_${positionMap[this.position]}`;
    }
  },

  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false;
        this.$el.addEventListener("transitionend", this.destroyElement);
      }
    }
  },

  methods: {
    destroyElement() {
      this.$el.removeEventListener("transitionend", this.destroyElement);
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },

    close() {
      this.closed = true;
      if (typeof this.onClose === "function") {
        this.onClose(this);
      }
    },

    clearTimer() {
      clearTimeout(this.timer);
    },

    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },
    keydown(e) {
      if (e.keyCode === 27) {
        if (!this.closed) {
          this.close();
        }
      }
    }
  },
  mounted() {
    this.startTimer();
    document.addEventListener("keydown", this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
  }
};
</script>

<style lang="scss">
@import "./message.sass";
</style>

<!--<style>
  @import './message.css';
</style>-->
