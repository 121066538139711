
import Vue from "vue";

import { sync } from "vuex-router-sync";
import App from "./components/App";
import router from "./router";
import store from "./vuex/store";

import linkify from "vue-linkify";

Vue.directive("linkified", linkify);

import * as constants from "./constants/strings";
import * as config from "./config";

//require("popper.js/dist/esm/popper.min");

require("bootstrap/js/dist/util");
require("bootstrap/js/dist/dropdown");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/carousel");
require("bootstrap/js/dist/tab");

require("font-awesome/css/font-awesome.min.css");
require("firebase/app");
require("firebase/messaging");

require("url-search-params-polyfill");
//const videojs = require('video.js');

/*import "leaflet/dist/leaflet.css";
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});*/

const moment = require("moment");
require("moment/locale/ru");
moment.locale("ru");

sync(store, router);

Vue.filter("formatDay", function(value) {
    if (value) {
        var m = value;
        if (m.isSame(moment(), "day")) {
            return "Сегодня";
        } else if (m.isSame(moment().add(-1, "days"), "day")) {
            return "Вчера";
        } else {
            return m.format("D MMMM YYYY");
        }
    }
    return null;
});

Vue.filter("formatTime", function(value) {
    if (value) {
        return value.format("LT");
    }
});

Vue.filter("formatDate", function(value) {
    if (value) {
        value.format();
    }
});

Vue.filter("parseDate", function(value) {
    if (value) {
        return moment.unix(value);
    }
});

const app = new Vue({
    router,
    store,
    ...App
});

Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$constants", { value: constants });
Object.defineProperty(Vue.prototype, "$config", { value: config });

export { app, router, store };
