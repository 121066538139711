<template>
  <div>
    <div
      v-if="plannedTariff && Object.keys(plannedTariff).length !== 0"
      class="tariff-wrapper">
      <modal-window
        v-if="showModal"
        :closable="closable"
        :show="showModal"
        :buttons="buttons"
        :header-text="header"
        :text="message"
        @removePlannedTariff="removePlannedTariff"
        @close="closeModalForm"
      />
      <div class="white-box_header">
        Запланированный тариф
      </div>
      <div class="white-box_body">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-8 col-xl-8">
            <tariff-card
              :id="plannedTariff.id"
              :name="plannedTariff.name"
              :speed-night="parseInt(plannedTariff.speed.night)"
              :speed-day="parseInt(plannedTariff.speed.day)"
              :speed-unit="plannedTariff.speed.unit"
              :bonus-text="plannedTariff.bonus"
              :price="parseFloat(plannedTariff.amount)"
              :meta-can-cancel="meta.canCancel"
              :planned-date="meta.planned"
              :show-cancel-tariff-button=true
              @cancelTariff="showModalForm"
              :credit-disabled="plannedTariff.credit.disabled"
              :credit-expired="plannedTariff.credit.expired"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import TariffCard from "./card.vue";
import { default as Message } from "../message/main.js";
import ModalWindow from "../modal/main";

export default {
  name: "TariffPlanned",

  /*props: {
    plannedTariff: {
      type: Object,
      default: null
    },
    meta: {
      type: Object,
      default: null
    },

  },*/

  computed: {
    plannedTariff() {
      return this.$store.getters["tariffs/getPlannedTariff"];
    },
    meta() {
      return this.$store.getters["tariffs/getMeta"];
    },
  },

  data: function() {
    return {
      showModal: false,
      message: null,
      header: null,
      controlButtons: [
        {
          text: 'Отменить',
          emitEvent: 'close'
        },
        {
          text: 'Продолжить',
          emitEvent: 'removePlannedTariff'
        }
      ],
      buttons: null
    };
  },

  components: {
    ModalWindow,
    // MoonLoader,
    TariffCard
  },

  methods: {
    removePlannedTariff() {
      this.message = null;
      this.header = null;
      this.buttons = null;
      this.closable = null;
      this.$store
        .dispatch("tariffs/deletePlannedTariff")
        .then(() => {
          this.$emit("update");
          this.closeModalForm();
          Message({
            message: "Запланированная смена тарифа успешно отменена.",
            type: 'success',
            position: "right",
            showClose: true,
          });
        })
        .catch(error => {
          console.log(error)
          this.closeModalForm();
          this.closeModalForm();
          Message({
            message:
                "Не удалось отменить запланированную смену тарифа, попробуйте позже.",
            type: 'error',
            position: "right",
            showClose: true,
          });
        });
      },

    closeModalForm() {
      this.header = null;
      this.closable = false;
      this.showModal = false;
      this.message = null;
    },

    showModalForm() {
      this.header = 'Внимание!';
      this.buttons = this.controlButtons;
      this.closable = true;
      this.showModal = true;
      this.message = 'Вы действительно хотите отменить переход на новый тариф?';
    }

  }
};
</script>
