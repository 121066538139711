<template>
  <div class="row">
    <div class="col-12 col-lg-1 icon-container">
      <template v-if="this.type == 1">
        <img 
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_cashbox.png')">
      </template>
      <template v-else-if="(type == 3) || (this.type == 4) || (this.type == 5) || (this.type == 10) || (this.type == 36) || (this.type == 52)">
        <img 
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_terminal.png')">
      </template>
      <template v-else-if="this.type == 9">
        <img 
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_gift.png')">
      </template>
      <template v-else-if="this.type == 38">
        <img 
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_cashbox.png')">
      </template>
      <template v-else-if="this.type == 39">
        <img 
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_sber.png')">
      </template>
      <template v-else-if="this.type == 43">
        <img
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_ring.png')">
      </template>
      <template v-else-if="this.type == 71">
        <img 
          height="55px" 
          width="55px"
          :src="require('@/assets/images/payments_card.png')">
      </template>
      <template v-else>
        <template v-if="this.amount < 0">
          <img 
            height="55px" 
            width="55px"
            :src="require('@/assets/images/payments_down.png')">
        </template>
        <template v-else>
          <img 
            height="55px" 
            width="55px"
            :src="require('@/assets/images/payments_up.png')">
        </template>
      </template>
    </div>
    <div class="col-12 col-lg-9 detail">
      <div 
        class="amount" 
        style="font-size: 18px;">{{ this.amount }} <i 
          class="fa fa-rub" 
          aria-hidden="true"/></div>
      <div>{{ this.detail }}</div>
    </div>
    <div
      class="col-12 col-lg-2 timestamp">
      {{ this.createdDate | formatDay }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DetalizationItem",

  props: {
    type: {
      type: Number,
      default: 0
    },
    amount: {
      type: String,
      default: null
    },
    detail: {
      type: String,
      default: ""
    },
    createdDate: {
      type: Object,
      default: null
    }
  }
};
</script>
