<template>
  <li
    :id="id"
    :class="{ even: isSupport(message), odd: isNotSupport(message)}">
    <div class="chat-content">
      <div class="box">
        <div class="chat-message">
          <span
            style="white-space: pre-wrap;" 
            v-html="message.message"
            v-linkified:options="{ className: 'link link_white' }" />
        </div>
        <template v-if="message.attachments">
          <vue-lightbox 
            v-for="file in message.attachments"
            :key="file.id"
            :src=file.original>
            <img 
              :src=file.preview 
              class="preview">
          </vue-lightbox>
        </template>
        <div class="chat-time">
          {{ chatTime }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import VueLightbox from "vue-lightbox";

export default {
  name: "ChatItem",
  props: {
    id: {
      type: String,
      default: null
    },
    message: {
      type: Object,
      default: null
    }
  },
  computed: {
    chatTime() {
      return this.$options.filters.formatTime(
          this.message.created
      );
    }
  },
  components: {
    VueLightbox
  },
  methods: {
    isSupport: function(message) {
      return message.support_id != null;
    },

    isNotSupport: function(message) {
      return !this.isSupport(message);
    }
  }
};
</script>

<style lang="scss">
@import "./item.sass";
</style>
