<template>
  <div class="wrapper">
    <template v-if="isLoading">
      <loader-component/>
    </template>
    <div v-else-if="isSuccess">
      <div class="row">
        <div class="col-12 offset-0 col-xl-8 offset-xl-2">
          <div class="white-box">
            <div class="white-box_body">
              <banners-component :banners="banners"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <error-component 
      v-else-if="!isSuccess"
      :message="message"
      @refresh="updateBannersInfo(true)"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoaderComponent from "../common/LoaderComponent.vue";
import ErrorComponent from "../common/ErrorComponent.vue";
import BannersComponent from "../common/BannersComponent.vue";

export default {
  name: "PageHome",

  data: function() {
    return {
      isSuccess: true,
      isLoading: false,
      showModal: false,
      message: null,
      postCreditMessage: null
    };
  },

  components: {
    BannersComponent,
    ErrorComponent,
    LoaderComponent
  },

  computed: {
    abonPayment() {
      return this.$store.getters["account/abonPayment"];
    },

    accountStatus() {
      return this.$store.getters["account/accountStatus"];
    },

    ...mapState({
      accountInfo: state => state.account.accountInfo,
      banners: state => state.banners.bannersArray
    })
  },

  methods: {
    updateBannersInfo(forced = false) {
      if (forced) {
        this.fetchBanners();
      } else {
        if (!this.banners || Object.keys(this.banners).length === 0) {
          this.fetchBanners();
        }
      }
    },
    fetchBanners() {
      this.isLoading = true;
      this.$store
        .dispatch("banners/fetchBanners")
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;
        })
        .catch(error => {
          console.log(error)

          this.isLoading = false;
          this.isSuccess = false;
          /* if (error.response) {
				            this.message = this.$constants.errors.unexpected;
				        } else {
				            this.message = this.$constants.errors.unexpected;
				        }*/
          this.message = this.$constants.errors.unexpected;
        });
    }
  },
  mounted() {
    this.updateBannersInfo();
  }
};
</script>
