<template>
  <div v-if="banners">
    <div 
      id="courierPlusImages" 
      class="carousel slide has-shadow" 
      data-ride="carousel">
      <div class="carousel-inner">
        <template 
          v-for="(banner, index) in banners">
          <div 
            v-if="index == 0"
            :key="index"
            class="carousel-item active">
            <div class="embed-responsive embed-responsive-21by9">
              <a :href="banner.link">
                <img 
                  class="embed-responsive-item" 
                  :src="banner.img" >
              </a>
            </div>
          </div>
          <div 
            v-else 
            class="carousel-item"
            :key="index">
            <div class="embed-responsive embed-responsive-21by9">
              <a :href="banner.link">
                <img 
                  class="embed-responsive-item" 
                  :src="banner.img">
              </a>
            </div>
          </div>
        </template>
      </div>
      <a 
        class="carousel-control-prev" 
        href="#courierPlusImages" 
        role="button" 
        data-slide="prev">
        <span 
          class="carousel-control-prev-icon" 
          aria-hidden="true"/>
        <span class="sr-only">Previous</span>
      </a>
      <a 
        class="carousel-control-next" 
        href="#courierPlusImages" 
        role="button" 
        data-slide="next">
        <span 
          class="carousel-control-next-icon" 
          aria-hidden="true"/>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannersComponent",
  props: {
    banners: {
      type: Array,
      default: null
    }
  },
  data: function() {
    return {
      errorMessage: ""
    };
  },
  components: {},
  methods: {},
  mounted() {
    /*$(".carousel").carousel({
      interval: 4000
    });*/
  }
};
</script>
