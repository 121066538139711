import Vue from "vue";
import VueRouter from "vue-router";

import Apps from "./components/pages/Apps";
import Cams from "./components/pages/Cams";
import Services from "./components/pages/Services";
import Stats from "./components/pages/Stats";

import Home from "./components/pages/Home";
import Info from "./components/pages/Info";
import Payments from "./components/pages/Payments";
import Auth from "./components/pages/Auth";
import Tariffs from "./components/pages/Tariffs.vue";
import Profile from "./components/pages/Profile.vue";
import Pay from "./components/pages/Pay.vue";
import Chat from "./components/pages/Chat.vue";
import Messages from "./components/pages/Messages.vue";
import * as config from "./config";
import * as Cookies from "tiny-cookie";
import store from "./vuex/store";

import * as constants from "./constants/strings";
import InternetSettings from "./components/pages/InternetSettings";

Vue.use(VueRouter);

function routesBeforeEnter(to, from, next) {
  if (store.state.access_token) {
    next();
  } else {
    if (!Cookies.get(config.cookie.name + "-access_token")) {
      console.log("!cookie");
      next({ path: "/" });
    } else {
      store.dispatch("setTokensFromCookies").then(() => {
        next();
      });
    }
  }
}

const routes = [
  {
    path: "/",
    name: "auth",
    component: Auth,
    meta: {
      title: (route) => {
        console.log(route);
        return "Авторизация | " + constants.text.courierPlus;
      },
      titleName: "Авторизация",
    },
    beforeEnter: (to, from, next) => {
      if (Cookies.get(config.cookie.name + "-access_token")) {
        store.dispatch("setTokensFromCookies");
        next("/home");
      } else {
        next();
      }
    },
  },

  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: (route) => {
        console.log(route);
        return "Главная страница | " + constants.text.courierPlus;
      },
      titleName: "Главная страница",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },

  {
    path: "/services",
    name: "services",
    component: Services,
    meta: {
      title: (route) => {
        console.log(route);
        return "Услуги | " + constants.text.courierPlus;
      },
      titleName: "Услуги",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },

  {
    path: "/stats",
    name: "stats",
    component: Stats,
    meta: {
      title: (route) => {
        console.log(route);
        return "Статистика | " + constants.text.courierPlus;
      },
      titleName: "Статистика",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },

  {
    path: "/info",
    name: "info",
    component: Info,
    meta: {
      title: (route) => {
        console.log(route);
        return "Реквизиты | " + constants.text.courierPlus;
      },
      titleName: "Реквизиты",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/detalization",
    name: "detalization",
    component: Payments,
    meta: {
      title: (route) => {
        console.log(route);
        return "Детализация | " + constants.text.courierPlus;
      },
      titleName: "Детализация",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/internet-options",
    name: "internet-options",
    component: InternetSettings,
    meta: {
      title: (route) => {
        console.log(route);
        return "Настройки интернета | " + constants.text.courierPlus;
      },
      titleName: "Настройки интернета",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/tariffs",
    name: "tariffs",
    component: Tariffs,
    meta: {
      title: (route) => {
        console.log(route);
        return "Тарифы | " + constants.text.courierPlus;
      },
      titleName: "Тарифы",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: (route) => {
        console.log(route);
        return "Профиль | " + constants.text.courierPlus;
      },
      titleName: "Профиль",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/pay",
    name: "pay",
    component: Pay,
    meta: {
      title: (route) => {
        console.log(route);
        return "Оплата | " + constants.text.courierPlus;
      },
      titleName: "Оплата",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/support",
    name: "support",
    component: Chat,
    meta: {
      title: (route) => {
        console.log(route);
        return "Техподдержка | " + constants.text.courierPlus;
      },
      titleName: "Техподдержка",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },

  {
    path: "/messages",
    name: "messages",
    component: Messages,
    meta: {
      title: (route) => {
        console.log(route);
        return "Уведомления | " + constants.text.courierPlus;
      },
      titleName: "Уведомления",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/cams",
    name: "cams",
    component: Cams,
    meta: {
      title: (route) => {
        console.log(route);
        return "Камеры | " + constants.text.courierPlus;
      },
      titleName: "Камеры",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "/apps",
    name: "apps",
    component: Apps,
    meta: {
      title: (route) => {
        console.log(route);
        return "Мобильные приложения | " + constants.text.courierPlus;
      },
      titleName: "Приложения",
    },
    beforeEnter: (to, from, next) => {
      routesBeforeEnter(to, from, next);
    },
  },
  {
    path: "A_CHAT",
    redirect: "/support",
  },
  {
    path: "A_MESSAGE",
    redirect: "/messages",
  },
  {
    path: "A_PAYMENTS",
    redirect: "/detalization",
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.meta.title(to)) {
    document.title = to.meta.title(to);
  }
  next();
});

export default router;
