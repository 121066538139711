<template>
  <div class="loader">
    <moon-loader :color="color"/>
  </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  name: "LoaderComponent",

  data: function() {
    return {
      color: "#2F458C"
    };
  },

  components: {
    MoonLoader
  },

  computed: {},

  methods: {}
};
</script>
<style>
.loader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
