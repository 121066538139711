<template>
  <nav 
    class="navbar fixed-top top-navbar" 
    role="navigation">
    <div class="nav-logo">
      <a href="http://shadrinsk.net">
        <img :src="require('@/assets/images/logo_kuryer_white.png')">
      </a>
    </div>
    <div 
      id="nav-left" 
      @click='clickActive' 
      class="nav-left">
      <a class="menu">
        <i class="fa fa-bars"/>
      </a>
    </div>

    <ul
      v-if="accountInfo.name !== null"
      class="navbar-nav ml-auto"
      id="profile-dropdown">
      <li
        class="nav-item"
        style="max-height: 60px; width: 100%; text-align: right;">
        <div class="dropdown">
          <a
            href="#"
            style="line-height: 40px; background-color: #f2f6fc; border-radius: 10px; padding-left: 20px; padding-right: 20px; padding-top: 8px; padding-bottom: 8px"
            role="button" 
            id="dropdownMenuLink" 
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false">
            {{ accountInfo.name }}
            <svg 
              version="1.1" 
              id="profile-svg"
              xmlns="http://www.w3.org/2000/svg" 
              x="0px"
              y="0px"
              viewBox="0 0 53 53" 
              style="enable-background: new 0 0 54 54; width: 30px; height: 30px; position: inherit; top: 10px"
              xml:space="preserve">
              <path 
                style="fill:#f2f6fc;"
                d="M18.613,41.552l-7.907,4.313c-0.464,0.253-0.881,0.564-1.269,0.903C14.047,50.655,19.998,53,26.5,53
              c6.454,0,12.367-2.31,16.964-6.144c-0.424-0.358-0.884-0.68-1.394-0.934l-8.467-4.233c-1.094-0.547-1.785-1.665-1.785-2.888v-3.322
              c0.238-0.271,0.51-0.619,0.801-1.03c1.154-1.63,2.027-3.423,2.632-5.304c1.086-0.335,1.886-1.338,1.886-2.53v-3.546
              c0-0.78-0.347-1.477-0.886-1.965v-5.126c0,0,1.053-7.977-9.75-7.977s-9.75,7.977-9.75,7.977v5.126
              c-0.54,0.488-0.886,1.185-0.886,1.965v3.546c0,0.934,0.491,1.756,1.226,2.231c0.886,3.857,3.206,6.633,3.206,6.633v3.24
              C20.296,39.899,19.65,40.986,18.613,41.552z"/>
              <g>
                <path 
                  style="fill:#2f458c;"
                  d="M26.953,0.004C12.32-0.246,0.254,11.414,0.004,26.047C-0.138,34.344,3.56,41.801,9.448,46.76
                c0.385-0.336,0.798-0.644,1.257-0.894l7.907-4.313c1.037-0.566,1.683-1.653,1.683-2.835v-3.24c0,0-2.321-2.776-3.206-6.633
                c-0.734-0.475-1.226-1.296-1.226-2.231v-3.546c0-0.78,0.347-1.477,0.886-1.965v-5.126c0,0-1.053-7.977,9.75-7.977
                s9.75,7.977,9.75,7.977v5.126c0.54,0.488,0.886,1.185,0.886,1.965v3.546c0,1.192-0.8,2.195-1.886,2.53
                c-0.605,1.881-1.478,3.674-2.632,5.304c-0.291,0.411-0.563,0.759-0.801,1.03V38.8c0,1.223,0.691,2.342,1.785,2.888l8.467,4.233
                c0.508,0.254,0.967,0.575,1.39,0.932c5.71-4.762,9.399-11.882,9.536-19.9C53.246,12.32,41.587,0.254,26.953,0.004z"/>
              </g>
            </svg>

          </a>
          <div
            class="dropdown-menu dropdown-menu-right" 
            aria-labelledby="dropdownMenuButton">
            <!--<div>{{ accountInfo.login }}</div>
            <div{{ accountInfo.account }}</div>-->
            <router-link
              class="dropdown-item"
              :to="{ name: 'profile' }">
              Профиль
            </router-link>
            <a 
              href="#"
              class="dropdown-item"
              @click="exit">
              Выйти
            </a>
          </div>
        </div>


      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TopComponent",

  data: function() {
    return {
      sideBar: null,
      sideBarButton: null,
      isActive: false
    };
  },

  computed: {
    accountInfo() {
      return this.$store.getters['account/getAccountInfo']
    }
  },

  mounted: function() {
    this.sideBar = document.getElementsByClassName("navbar-static-side")[0];
    this.sideBarButton = document.getElementsByClassName("nav-left")[0];
  },

  methods: {
    clickActive: function() {
      this.isActive = !this.isActive;
      $("body").toggleClass("enlarged");
    },
    exit() {
      const currentToken = window.localStorage.getItem(
          "sentFirebaseMessagingToken"
      );
      if (currentToken) {
        this.$store
            .dispatch(
                "deleteDeviceId",
                window.localStorage.getItem("sentFirebaseMessagingToken")
            )
            .then(() => {
              Promise.all([
                this.$store.dispatch("exit"),
                this.$store.dispatch("clearAll")
              ]).then(() => {
                this.$router.push({ path: "/" });
              });
            });
        window.localStorage.setItem("sentFirebaseMessagingToken", "");
      } else {
        Promise.all([
          this.$store.dispatch("exit"),
          this.$store.dispatch("clearAll")
        ]).then(() => {
          this.$router.push({ path: "/" });
        });
      }
    }
  }
};
</script>

<style>
  .avatar:after {
    display: none;
  }

  .avatar:before {
    display: none;
  }

  .avatar-lg, .avatar-lg > * {
    line-height: 50px !important;
  }

  .avatar-lg.avatar-icon {
    font-size: 24px !important;
  }

  .avatar-lg {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
  }

  .avatar {
    align-items: right;
    font-size: 14px !important;
    color: rgba(0,0,0,.65) !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    margin: 0 0 0 0 !important;
    padding: 0 !important;
    list-style: none !important;
    display: inline-block !important;
    text-align: center !important;
    background: #ccc !important;
    color: #fff !important;
    white-space: nowrap !important;
    position: relative !important;
    overflow: hidden !important;
    vertical-align: middle !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
    border-radius: 50% !important;
  }
</style>
