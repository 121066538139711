import Vue from "vue";
import Vuex from "vuex";
import * as api from "../api/courier_api.js";
import * as Cookies from "tiny-cookie";
import * as config from "../config";
import banners from "./modules/banners_store.js";
import account from "./modules/account_store.js";
import tariffs from "./modules/tariffs_store";
import chatroom from "./modules/chatroom_store";
import payments from './modules/payments_store';
import messages from './modules/messages_store';

Vue.use(Vuex);

const state = {
  access_token: "",
  refresh_token: "",
  isAuthorized: false,
  //fpstSession: null,
  embedClientUrl: null
};

const mutations = {
  SET_FPST_EMBED_CLIENT_URL(state, payload) {
    if (payload.data.data.url) {
      state.embedClientUrl = payload.data.data.url.replace(
          "http://",
          "https://"
      );
    }
  },

  /*SET_FPST_SESSION(state, payload) {
    state.fpstSession = payload.data.data.session;
    Cookies.setCookie(
      config.cookie.name + "-fpst-session",
      payload.data.data.session,
      { domain: config.cookie.domain, expires: config.cookie.expires }
    );
  },

  CLEAR_FPST_SESSION(state) {
    state.fpstSession = "";
    Cookies.remove(
      config.cookie.name + "-fpst-session",
      payload.data.data.session,
      { domain: config.cookie.domain }
    );
  },

  SET_FPST_SESSION_FROM_COOKIE(state) {
    var fpstSession = Cookies.setCookie(
      config.cookie.name + "-fpst-session",
      payload.data.data.session,
      { domain: config.cookie.domain }
    );

    if (fpstSession) {
      state.fpstSession = fpstSession;
    }
  },*/

  SET_TOKENS(state, payload) {
    console.log("set_tokens");
    state.isAuthorized = true;
    state.access_token = payload.data.access_token;
    state.refresh_token = payload.data.refresh_token;
    Cookies.setCookie(
        config.cookie.name + "-access_token",
        payload.data.access_token,
        {
          domain: config.cookie.domain,
          expires: config.cookie.expires,
          secure: true
        }
    );
    Cookies.setCookie(
        config.cookie.name + "-refresh_token",
        payload.data.refresh_token,
        {
          domain: config.cookie.domain,
          expires: config.cookie.expires,
          secure: true
        }
    );
  },

  CLEAR_TOKENS(state) {
    state.access_token = "";
    state.refresh_token = "";
    Cookies.remove(config.cookie.name + "-access_token", {
      domain: config.cookie.domain
    });
    Cookies.remove(config.cookie.name + "-refresh_token", {
      domain: config.cookie.domain
    });
    state.isAuthorized = false;
  },

  SET_TOKENS_FROM_COOKIES(state) {
    var access_token = Cookies.get(config.cookie.name + "-access_token");
    var refresh_token = Cookies.get(config.cookie.name + "-refresh_token");

    if (access_token && refresh_token) {
      state.access_token = access_token;
      state.refresh_token = refresh_token;
      state.isAuthorized = true;
    }
  },

  CLEAR_STATE(state) {
    state.access_token = "";
    state.refresh_token = "";
    state.isAuthorized = false;
  }
};

const actions = {
  fetchFpstEmbedClientUrl: ({ commit }) => {
    return api.fpst.postFpstEmbedClientUrl().then(data => {
      commit("SET_FPST_EMBED_CLIENT_URL", { data });
      return data;
    });
  },

  /*fetchFpstSession: ({ commit, state }) => {
    if (!state.fpstSession) {
      return api.fpst.postFpstSession().then(data => {
        console.log("fetchFpstSession");
        console.log(data);
        console.log("///");
        commit("SET_FPST_SESSION", { data });
        return data;
      });
    } else {
      return state.fpstSession;
    }
  },*/

  login: ({ commit }, creditionals) => {
    console.log("dispatch-login");
    return api.account.login(creditionals).then(data => {
      commit("SET_TOKENS", { data });
      return true;
    });
  },

  // eslint-disable-next-line no-unused-vars
  changePassword: ({ commit }, creditionals) => {

    // eslint-disable-next-line no-unused-vars
    return api.account.changePassword(creditionals).then(data => {
      //commit('SET_TOKENS', { data })
      return true;
    });
  },

  refreshToken: ({ commit, state }) => {
    return api.account.refreshToken(state.refresh_token).then(data => {
      commit("SET_TOKENS", { data });
      return data;
    });
  },

  setTokensFromCookies: ({ commit }) => {
    commit("SET_TOKENS_FROM_COOKIES");
  },

  setFpstSessionFromCookie: ({ commit }) => {
    commit("SET_FPST_SESSION_FROM_COOKIE");
  },

  exit: ({ commit }) => {
    commit("CLEAR_TOKENS");
  },

  // eslint-disable-next-line no-unused-vars
  putDeviceId: ({ commit }, deviceId) => {
    return api.pushes.putDeviceId(deviceId).then(data => {
      return data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteDeviceId: ({ commit }, deviceId) => {
    return api.pushes.deleteDeviceId(deviceId).then(data => {
      return data;
    });
  },

  clearAll: ({ commit }) => {
    commit("account/CLEAR_STATE");
    commit("banners/CLEAR_STATE");
    commit("chatroom/CLEAR_STATE");
    commit("messages/CLEAR_STATE");
    commit("payments/CLEAR_STATE");
    commit("tariffs/CLEAR_STATE");

    commit("CLEAR_STATE");
  }
};

const getters = {
  getEmbedClientUrl: state => {
    return state.embedClientUrl;
  },

  /*getFpstSessionId: state => {
    console.log("getFpstSessionId: " + state.fpstSession);
    return state.fpstSession;
  },*/

  getRefreshToken: state => {
    return state.refresh_token;
  },

  getAcessToken: state => {
    return state.access_token;
  }

  /* getBadgesChat: state => {
        console.log('badgesChat: ' + state.badgesChat)
        return state.badgesChat;
    },

    getBadgesMessages: state => {
        console.log('badgesMessages: ' + state.badgesMessages)
        return state.badgesMessages;
    },*/
};

export default new Vuex.Store({
  modules: {
    account,
    tariffs,
    banners,
    chatroom,
    payments,
    messages
  },

  state,
  mutations,
  actions,
  getters
});
