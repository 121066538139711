<template>
  <div>
    <template v-if="showModal">
      <modal-window
          v-if="showModal"
          :closable="closable"
          :show="showModal"
          :show-mobile-apps-promo="showMobileAppsPromo"
          :text="message"
          :header-text="headerText"
          :buttons="buttons"
          @postCredit="postCredit"
          @close="closeModalForm"/>
    </template>
    
    <template v-if="isLoading">
      <loader-component style="height: 130px;"/>
    </template>
    
    <template v-else-if="isSuccess">
      <div
          style="padding:10px"
          class="white-box"
          id="head-component-container">
        
        <div class="items">
          <div class="row">
            
            <div class="col-md-12 col-lg-6">
              <div class="payments_summ" style="text-align: center">
                <span
                    v-if="(!accountInfo.disabled && !accountInfo.suspended && accountInfo.amount > 0)"
                    class="summ summ_plus">{{ parseFloat(accountInfo.amount).toFixed(2) }}
                  <i
                      class="fa fa-rub"
                      aria-hidden="true"/>
                </span>
                <span
                    v-else
                    class="summ summ_minus">{{ parseFloat(accountInfo.amount).toFixed(2) }}
                  <i
                      class="fa fa-rub"
                      aria-hidden="true"/>
                </span>
              </div>
              
              <div style="text-align: center">
                {{ accountStatus }}
              </div>
            
            </div>
            <div class="col-md-12 col-lg-6">
              <div>
                Ваш тариф:
                <router-link
                    class="link info"
                    style=""
                    :to="{ name: 'tariffs' }">
                  <template v-if="plannedTariff !== null">
                    <del>{{ currentTariff.name }}</del>
                    {{ plannedTariff.name }} (с {{ meta.planned | formatDay }})
                  </template>
                  <template v-else>
                    {{ currentTariff.name }}
                  </template>
                </router-link>
              </div>
              
              <div style="padding-top: 14px;">
                Абонентская плата:
                <template v-if="servicesCount > 1">
                  <router-link
                      class="link info"
                      :to="{ name: 'services' }"
                  >
                    <div
                        style="font-weight: 500;"
                        class="d-inline-block">
                      <template v-if="abonPayment !== plannedAbonPayment">
                        <del>{{ abonPayment }}
                          <i
                              class="fa fa-rub"
                              aria-hidden="true"/></del>
                        {{ plannedAbonPayment }}
                        <i
                            class="fa fa-rub"
                            aria-hidden="true"/>
                      </template>
                      <template v-else>
                        {{ abonPayment }}
                        <i
                            class="fa fa-rub"
                            aria-hidden="true"/>
                      </template>
                    </div>
                    за {{ servicesCount }} {{ getNoun(servicesCount, 'услугу', 'услуги', 'услуг') }}
                  </router-link>
                </template>
                <template v-else>
                  <div
                      style="font-weight: bold"
                      class="d-inline-block">{{ abonPayment }}
                  </div>
                  <i
                      class="fa fa-rub"
                      aria-hidden="true"/>
                </template>
              </div>
              
              <div style="padding-top: 14px">
                Последний платеж:
                <router-link
                    class="link info"
                    :to="{ name: 'detalization' }"
                >
                  <div
                      style="font-weight: bold"
                      class="d-inline-block">{{ latestPlusPayment.amount }}
                  </div>
                  <i
                      class="fa fa-rub"
                      aria-hidden="true"/> ({{ latestPlusPayment.created | formatDay }})
                </router-link>
              </div>
            
            </div>
          
          </div>
          
          <div class="row"
               @click="redirectToLoyaltyProgramPage"
          >
            <div class="col-6" style="text-align: right">
              {{ loyaltyPointsFormatted }} {{ getNoun(loyaltyPointsFormatted, 'бонус', 'бонуса', 'бонусов') }}
            </div>
            <div class="col-6" style="text-align: left">
              <div class="link info" style="cursor: pointer">
                Как получить?
              </div>
            </div>
          </div>
          
          <div
              class="row">
            <div class="col-6" style="text-align: right">
              <button-element
                  round
                  @click="redirectToPayPage"
              >
                {{ $constants.payments.pay_action }}
              </button-element>
            </div>
            <div class="col-6" style="text-align: left">
              <button-element
                  round
                  @click="showPostCreditModal">
                {{ $constants.payments.promised }}
              </button-element>
            </div>
          </div>
        
        
        </div>
      </div>
    </template>
    <error-component
        @refresh="fetchAccountInfo(true)"
        v-else-if="!isSuccess"
        :message="errorMessage"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LoaderComponent from "../common/LoaderComponent.vue";
import ErrorComponent from "./ErrorComponent.vue";
import ButtonElement from "../button/button";
import ModalWindow from "../modal/main";

import {default as Message} from "../message/main.js";

export default {
  name: "HeadComponent",
  data: function () {
    return {
      isSuccess: false,
      isLoading: false,
      showModal: false,
      showMobileAppsPromo: true,
      errorMessage: null,
      modalType: null,
      
      closable: false,
      headerText: null,
      message: null,
      buttons: null,
      controlButtons: [
        {
          text: 'Отменить',
          emitEvent: 'close'
        },
        {
          text: 'Продолжить',
          emitEvent: 'postCredit'
        }
      ]
    };
  },
  components: {
    LoaderComponent,
    ErrorComponent,
    ButtonElement,
    ModalWindow
  },
  
  computed: {
    meta() {
      return this.$store.getters["tariffs/getMeta"];
    },
    plannedAbonPayment() {
      return this.$store.getters["account/plannedAbonPayment"];
    },
    abonPayment() {
      return this.$store.getters["account/abonPayment"];
    },
    recommendedPay() {
      return this.$store.getters["account/recommendedPay"];
    },
    currentTariff() {
      return this.$store.getters["tariffs/getCurrentTariff"];
    },
    plannedTariff() {
      return this.$store.getters["tariffs/getPlannedTariff"];
    },
    ...mapState({
      accountInfo: state => state.account.accountInfo
    }),
    accountStatus() {
      return this.$store.getters["account/accountStatus"];
    },
    latestPlusPayment() {
      return this.$store.getters["payments/latestPlusPayment"] ? this.$store.getters["payments/latestPlusPayment"] : 0;
    },
    servicesCount() {
      return this.$store.getters["account/getServicesCount"];
    },
    loyaltyPoints() {
      return this.accountInfo.loyalty_points / 100;
    },
    loyaltyPointsFormatted() {
      return parseFloat(this.loyaltyPoints).toFixed(2);
    },
  },
  methods: {
    showPostCreditModal() {
      this.headerText = 'Обещанный платеж';
      this.buttons = this.controlButtons;
      this.message = this.$constants.payments.promisedInfo.slice();
      if (this.currentTariff.credit !== null) {
        let expiredDate = this.$moment().add(this.currentTariff.credit.expired, 'days').format("D MMMM YYYY");
        this.message[2] = "Обещанный  платеж предоставляется до " + expiredDate + ". " + this.message[2];
      }
      this.closable = true;
      this.showModal = true;
    },
    redirectToLoyaltyProgramPage() {
      window.open("https://kp45.ru/customers/bonus", '_blank');
    },
    fetchAccountInfo(forced = false) {
      if (forced) {
        this.setAccountInfo();
      } else {
        if (
            !this.accountInfo ||
            Object.keys(this.accountInfo).length === 0 ||
            (!this.currentTariff ||
                Object.keys(this.currentTariff.length === 0)
            )
        ) {
          this.setAccountInfo();
        }
      }
    },
    setAccountInfo() {
      this.isLoading = true;
      this.$store
          .dispatch("account/fetchAccountInfo", ["tariffs", "services", "credit"])
          .then(() => {
            this.$store.dispatch('payments/fetchLatestPlusPayment')
                .then(() => {
                  this.isSuccess = true;
                  this.isLoading = false;
                })
                .catch(error => {
                  this.isSuccess = true;
                  this.isLoading = false;
                  if (error.response) {
                    this.errorMessage = this.$constants.errors.unexpected;
                  } else {
                    this.errorMessage = this.$constants.errors.unexpected;
                  }
                });
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
            this.isSuccess = false;
            if (error.response) {
              this.errorMessage = this.$constants.errors.unexpected;
            } else {
              this.errorMessage = this.$constants.errors.unexpected;
            }
          });
    },
    
    postCredit() {
      this.message = null;
      this.closable = false;
      this.buttons = null;
      this.headerText = null;
      
      this.$store
          .dispatch("account/postCredit")
          .then(data => {
            if (data.status !== null && data.status == "success") {
              this.$store
                  .dispatch("account/fetchAccountInfo", ["credit"])
                  .then(() => {
                    this.closeModalForm();
                    Message({
                      message:
                          "Услуга успешно подключена.",
                      position: "right",
                      showClose: true,
                      type: 'success'
                    });
                  });
            }
          })
          .catch(error => {
            this.closeModalForm();
            const defaultMessageError = this.$constants.errors.promisedErrors.default;
            
            if (error.response) {
              if (error.response.status == 400) {
                const message = error.response?.data?.message ?? defaultMessageError;
                Message({
                  message: message,
                  position: "right",
                  showClose: true,
                  type: 'error'
                });
              }
              return;
            }
            Message({
              message:
              defaultMessageError,
              position: "right",
              showClose: true,
              type: 'error'
            });
          });
    },
    
    closeModalForm() {
      if (this.showModal) {
        this.showModal = false;
      }
      this.headerText = null;
      this.buttons = null;
      this.closable = false;
      this.message = null;
      console.log('closeModalForm');
      console.log('echo');
      
    },
    
    redirectToPayPage() {
      this.$router.push({name: 'pay'});
    },
    
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    }
  },
  
  created() {
    this.setAccountInfo();
  },
  
  mounted() {
  
  }
};
</script>