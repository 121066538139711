<template>
  <div class="detalization-wrapper">
    <div 
      v-if="isSuccess" 
      class="white-box">
      <div class="white-box_header">
        Детализация
      </div>
      <div class="white-box_body">
        <div class="items">
          <template v-for="key in paymentsKeys">
            <detalization-item
              :key="key"
              :type="payments[key].type"
              :amount="payments[key].amount"
              :detail="payments[key].detail"
              :created-date="payments[key].created"
            />
          </template>
        </div>
      </div>
      <infinite-loading 
        @infinite="infiniteHandler"
        force-use-infinite-wrapper="true">
        <template v-if="payments !== null && Object.keys(payments).length > 0">
          <span slot="no-more"/>
          <span slot="no-results"/>
        </template>
        <template v-else>
          <span slot="no-more"/>
          <span slot="no-results">{{ this.$constants.detalization.no_results }}</span>
        </template>
      </infinite-loading>
    </div>
    <error-component
      @refresh="setAccountPayments()"
      v-else-if="!isSuccess"
      :message="message"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import ErrorComponent from "./ErrorComponent.vue";
import DetalizationItem from "../detalization/DetalizationItem.vue";

export default {
  name: "DetalizationComponent",

  data: function() {
    return {
      isSuccess: true,
      message: null
    };
  },

  components: {
    InfiniteLoading,
    ErrorComponent,
    DetalizationItem
  },

  computed: {
    ...mapGetters({
      paymentsTotalPages: "payments/allPaymentsTotalPages",
      paymentsCurrentPage: "payments/allPaymentsCurrentPage"
    }),

    payments() {
      return this.$store.getters["payments/payments"];
    },
    paymentsKeys() {
      return this.$store.getters["payments/allKeys"];
    }
  },

  methods: {
    setAccountPayments() {
      this.isSuccess = true;
    },

    infiniteHandler($state) {
      this.$store
        .dispatch("payments/fetchPayments", this.paymentsCurrentPage + 1)
        .then(() => {
          $state.loaded();
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.status == 404) {
              console.log("404");
              $state.complete();
            } else {
              this.isSuccess = false;
              this.message = this.$constants.errors.unexpected;
              console.log(error);
            }
          } else {
            this.isSuccess = false;
            this.message = this.$constants.errors.unexpected;
            console.log(error);
          }
        });
    }
  }
};
</script>
