<template>
  <div class="white-box">
    <div class="white-box_header">Реквизиты</div>
    <div class="white-box_body">
      <div class="items">
        <div class="row">
          <div class="col-12 col-md-6">
            {{ $constants.network.ip_address }}
          </div>
          <div class="col-12 col-md-6">
            {{ network.ip }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            {{ $constants.network.mask }}
          </div>
          <div class="col-12 col-md-6">
            {{ network.mask }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            {{ $constants.network.gateway }}
          </div>
          <div class="col-12 col-md-6">
            {{ network.gateway }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            {{ $constants.network.dns1 }}
          </div>
          <div class="col-12 col-md-6">
            {{ network.dns1 }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            {{ $constants.network.dns2 }}
          </div>
          <div class="col-12 col-md-6">
            {{ network.dns2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetworkComponent",
  props: {
    network: {
      type: Object,
      default: null
    }
  }
};
</script>

<style>
.info > .row {
  border-top: 1px solid #eceeef;
  padding-bottom: 15px;
  padding-top: 15px;
}

.info > .row:first-child {
  padding-top: 0;
  border-top: 0;
}

.info > .row:last-child {
  padding-bottom: 0;
}
</style>
